<template>
  <div class="deviceApproval">
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../components/firebase/firebaseInit.js";

export default {
  name: "Device Approval",
  data() {
    return {
      error: "",
      errorMsg: "",
      errors: null,
      newDeviceId: null,
      currentUser: null,
    };
  },
  components: {},
  async created() {
    console.log("DEVICE APPROVAL")
    this.id = this.$route.query.id;

    await db
      .collection("mail")
      .doc(this.id)
      .get()
      .then((doc) => {
        //check if registration invite exists and get data for registration
        if (doc.exists) {
          //check if registration invite has expired
          if (doc.data().expired == true) {
            this.$router.push("/");
            //if registration invite has not expired get data and get user doc to populate form
          } else {
            // console.log("Document data:", doc.data());
            this.newDeviceId = doc.data().newDeviceId;
            this.currentUser = doc.data().userId;

              db.collection("users")
                .doc(this.currentUser)
                .collection("approvedDevices")
                .doc(this.newDeviceId)
                .set({
                  deviceId: this.newDeviceId,
                  userId: this.currentUser
                })
                .then(() => {
                  console.log("Document successfully written!");
                  this.$router.push("/");
                })
                .catch((error) => {
                  console.error("Error writing document: ", error);
                });
            
          }
          //if registration invite does not exist
        } else {
          this.$router.push("/");
          console.log("No such document!");
        }
      });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.register {
  h2 {
    max-width: 350px;
  }
}
.inviteRegistration {
  display: flex;
  justify-items: center;
  align-items: center;
  background-image: url("../assets/loginbackground.jpg");
  width: 100vw;
  height: 100vh;
}
</style>

